// External Imports
import { Fragment, useContext, useEffect, useState } from "react";
import Heading from "../UI/Heading";
import DetailPair from "../UI/DetailPair";
import SelectPair from "../UI/SelectPair";
import Switch from "../UI/Switch";
import { DataContext } from "../../context/data-context";
import { requestAdminAPI } from "../../api/posh-api";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../UI/LoadingSpinner";
import DropDownSearch from "../UI/DropDownSearch";
import VehicleCard from "../vehicles-page/VehicleCard";
import { MdDelete } from "react-icons/md";

// Internal Imports

/** ========================================================================
 * * Add Employee Component
 * This component renders a page to add an employee.
 * ========================================================================== */

export default function AddEmployee() {
  const navigate = useNavigate();
  const { locations, vehicles } = useContext(DataContext);

  // Initialze States
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [location, setLocation] = useState(locations[0].id);
  const [allocatedVehicles, setAllocatedVehicles] = useState([]);
  const [privilege, setPrivilege] = useState("Admin");

  /* ======================  ====================== */
  const addEmployeeHandler = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const payload = {
        name: e.target["Full Name"].value,
        email: e.target["Email Address"].value,
        phone: e.target["Phone Number"].value,
        location: e.target["Location"].value,
        privilege: e.target["Privelege"].value.toLowerCase(),
        password: e.target["Super Admin Password"].value,
        allocatedVehicles: allocatedVehicles,
      };
      await requestAdminAPI("add-employee", payload);
      navigate("/employees");
      setLoading(false);
    } catch (err) {
      console.error(err);
      setWarning(err.message);
      setLoading(false);
    }
  };

  /* ====================== FILTER VEHICLES BY LOCATION  ====================== */
  const filterVehicles = (location, allocatedVehicles) => {
    const filtered = vehicles.filter(
      (vehicle) => vehicle.location === location
    );
    allocatedVehicles.forEach((vehicle) => {
      const index = filtered.findIndex((v) => v.id === vehicle);
      if (index !== -1) filtered.splice(index, 1);
    });
    return filtered;
  };

  /* ====================== DESELECT VEHICLE HANDLER  ====================== */
  const deselectVehicleHandler = (id) => {
    const newAllocatedVehicles = allocatedVehicles.filter((v) => v !== id);
    setAllocatedVehicles(newAllocatedVehicles);
  };

  // Reset Allocated Vehicles on Location Change
  useEffect(() => {
    setAllocatedVehicles([]);
  }, [location]);

  return (
    <div className="container">
      <Heading
        title="Add Employee"
        isBackArrow={true}
        text="This feature is under-development. 
            Currently, admin privilege creates an admin account with access to all vehicles. 
            Sales privileges creates an admin account with access to selected vehicles and porter account
            only creates an employee account without admin access."
      />
      <form onSubmit={addEmployeeHandler} className="items-end info">
        <DetailPair label="Full Name" edit={true} />
        <DetailPair label="Email Address" edit={true} />
        <DetailPair label="Phone Number" edit={true} />
        <SelectPair
          label="Location"
          options={locations.map((location) => location.id)}
          edit={true}
          onChange={(e) => setLocation(e.target.value)}
        />
        <SelectPair
          label="Privelege"
          options={["Admin", "Sales", "Porter"]}
          edit={true}
          onChange={(e) => setPrivilege(e.target.value)}
        />

        {privilege === "Sales" && (
          <Fragment>
            <div className="md:col-span-3">
              <DropDownSearch
                title="Allocate Vehicles"
                options={filterVehicles(location, allocatedVehicles)}
                nameFormatter={(v) =>
                  `${v.year} ${v.model} ${v.color} ${
                    v.license
                  } - ${v.vin?.substring(13, 17)}`
                }
                selectHandler={(v) =>
                  setAllocatedVehicles([...allocatedVehicles, v])
                }
              />
            </div>
            <div className="max-h-[400px] list md:col-span-3">
              {allocatedVehicles.reverse().map((id) => {
                const vehicle = vehicles.find((v) => v.id === id);
                if (vehicle)
                  return (
                    <VehicleCard
                      vehicle={vehicle}
                      onClick={() => deselectVehicleHandler(id)}
                    />
                  );
              })}
            </div>
          </Fragment>
        )}

        <div className="grid items-end gap-4 md:grid-cols-3 md:col-span-3">
          <DetailPair
            label="Super Admin Password"
            type="password"
            edit={true}
          />
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="grid items-center gap-4 md:flex md:col-span-2">
              <button className="btn-primary md:w-fit" type="submit">
                Submit
              </button>
              {warning && <p className="warning">{warning}</p>}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
