// External Imports
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { MdRefresh } from "react-icons/md";
import { BiDownload } from "react-icons/bi";
import { RiSteeringFill } from "react-icons/ri";

// Internal Imports
import { AuthContext } from "../../context/auth-context";
import { DataContext } from "../../context/data-context";
import { arrayToCsv } from "../../util/arrayToCsv";
import { fetchPartnerById, fetchSmartcarAuth } from "../../api/fetch";
import Heading from "../UI/Heading";
import SearchBar from "../UI/SearchBar";
import PartnerCard from "./PartnerCard";
import { UtilityContext } from "../../context/util-context";
import { FaChartBar } from "react-icons/fa";

export default function Partners() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const searchParam = params.get("search") ?? "";

  // Load Context
  const { user } = useContext(AuthContext);
  const { partners, fetchPartners } = useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [filteredPartners, setFilteredPartners] = useState(partners);
  const [searchValue, setSearchValue] = useState(searchParam);

  const onSetupSmartcar = async () => {
    try {
      const response = await fetchSmartcarAuth(user);
      const { link } = response;
      window.open(link, "_blank");
    } catch (err) {
      console.log(err.message);
    }
  };
  // Fetch Partners and apply filters
  useEffect(() => {
    let results = [...partners];
    if (searchValue) {
      results = results.filter((partner) => {
        const string = `${partner.name} ${partner.email} ${partner.phone}`;
        return string.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    setFilteredPartners([...(results ?? [])]);
  }, [searchValue, partners]);

  const refreshHandler = () => {
    fetchPartners();
  };

  const downloadCSV = async () => {
    let arr = [];
    await Promise.all(
      filteredPartners.map(async (partner) => {
        const res = await fetchPartnerById(user, partner.id);
        partner.vehicles = res.vehicles;
      })
    );

    filteredPartners.forEach((partner) => {
      partner.vehicles.forEach((vehicle) => {
        const entry = {
          partner: partner.name,
          make: vehicle.make,
          model: vehicle.model,
          year: vehicle.year,
          vin: vehicle.vin,
          license: vehicle.license,
          state: vehicle.state,
          bos: vehicle.bos || "null",
          title: vehicle.title || "null",
          registeration: vehicle.registeration || "null",
          vir: vehicle.vir || "null",
          insurance: vehicle.insurance || "null",
          poa: vehicle.poa || "null",
          agreement: vehicle.agreement || "null",
          financed: vehicle.financed ? "yes" : "no",
          institution: vehicle.institution || "null",
          amount: vehicle.amount || "null",
          term: vehicle.term || "null",
          monthly: vehicle.monthly || "null",
          due_date: vehicle.due
            ? format(new Date(vehicle.due), "yyyy-MM-dd")
            : "null",
          loan_docs: vehicle.loan || "null",
        };
        arr.push(entry);
      });
    });
    arrayToCsv(arr, "partner-vehicles");
  };

  // Clear Search Filter
  const clearSearch = () => {
    setSearchValue("");
    navigate(`/partners?search=`, {}, { replace: true });
  };
  // Search handler
  const searchHandler = (value) => {
    navigate(`/partners?search=${value}`, {}, { replace: true });
    setSearchValue(value);
  };

  return (
    <div className="container">
      <Heading title="Partners" isBackArrow={false}>
        <FaChartBar
          onClick={() => navigate("/partners/report")}
          className="text-xl text-white cursor-pointer hover:text-stone-400"
        />
        <RiSteeringFill
          onClick={onSetupSmartcar}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
        />
        <BiDownload
          onClick={downloadCSV}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
        />
        <MdRefresh
          onClick={refreshHandler}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
        />
      </Heading>

      <SearchBar
        searchHandler={searchHandler}
        clearSearch={clearSearch}
        searchValue={searchValue}
      />

      <div className="h-[700px] overflow-scroll">
        <div className="grid gap-8">
          {filteredPartners.map((partner, idx) => (
            <PartnerCard key={idx} partner={partner} />
          ))}
        </div>
      </div>
    </div>
  );
}
