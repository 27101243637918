// External Imports
import { useContext, useState } from "react";
import Heading from "../UI/Heading";
import { DataContext } from "../../context/data-context";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import DetailPair from "../UI/DetailPair";

// Internal Imports

/** ========================================================================
 * * PartnerReports Component
 * This component renders a page to display reports about partner's vehicles performance.
 * ========================================================================== */

export default function PartnerReports() {
  const [loading, setLoading] = useState();
  const { partners, vehicles } = useContext(DataContext);

  /* ======================  ====================== */
  const data = {
    totalPartners: 0,
    totalActiveVehicles: 0,
    totalBookings: 0,
  };

  // Create a map for partners
  const partnerMap = {};
  partners?.forEach((partner) => {
    partnerMap[partner.id] = {
      id: partner.id,
      name: partner.name.split(" ")[0],
      bookings: 0,
      activeVehicles: 0,
    };
    data.totalPartners++;
  });
  console.log(partnerMap);
  // Loop over all vehicles
  vehicles.forEach((vehicle) => {
    if (!vehicle.partnerId) return;
    if (vehicle.bookings?.length > 0) {
      partnerMap[vehicle.partnerId].bookings++;
      data.totalBookings++;
    }
    partnerMap[vehicle.partnerId].activeVehicles++;
    data.totalActiveVehicles++;
  });

  // Use partner map to calculate utilization
  let graphData = [];
  Object.keys(partnerMap).forEach((key) => {
    if (partnerMap[key].activeVehicles < 1) return;
    let utilizationRate =
      (partnerMap[key].bookings * 100) / (partnerMap[key].activeVehicles || 1);
    utilizationRate = utilizationRate.toFixed(2);
    const entry = {
      name: partnerMap[key].name,
      utilization: utilizationRate,
      activeVehicles: partnerMap[key].activeVehicles,
      bookings: partnerMap[key].bookings,
    };
    graphData.push(entry);
  });

  // Calculate Overall Utilization Rate
  let utilizationRate =
    (data.totalBookings * 100) / (data.totalActiveVehicles || 1);
  utilizationRate = utilizationRate.toFixed(2);
  data.utilization = utilizationRate;

  return (
    <div className="container">
      <Heading
        title="Partners Report"
        text="The following data represents the statistics at the current time."
      />
      <div className="w-full my-4 md:my-8 h-52 md:h-72">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={graphData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis tick={{ fontSize: 12 }} />
            <Tooltip
              content={({ active, payload }) =>
                active && payload && payload.length ? (
                  <div className="p-4 bg-white rounded-lg text-stone-900">
                    <p>{`Utilization Rate: ${payload[0]?.value}%`}</p>
                    <p>{`Active Vehicles: ${payload[0]?.payload?.activeVehicles}`}</p>
                    <p>{`Booked Vehicles: ${payload[0]?.payload?.bookings}`}</p>
                  </div>
                ) : null
              }
            />
            <Bar dataKey="utilization" stackId="a" fill="#ca8a04" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="info">
        <DetailPair label="Total Partners" value={data.totalPartners} />
        <DetailPair
          label="Total Active Vehicles"
          value={data.totalActiveVehicles}
        />
        <DetailPair
          label="Overall Utilization"
          value={`${data.utilization}%`}
        />
      </div>
    </div>
  );
}
