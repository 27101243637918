// External Imports
import { Fragment, useContext, useEffect, useState } from "react";
import { UtilityContext } from "../../context/util-context";
import { useSearchParams } from "react-router-dom";
import { requestAdminAPI } from "../../api/posh-api";
import Heading from "../UI/Heading";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import {
  timestampToDate,
  timestampToString,
} from "../../util/timestamp-to-date";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { format } from "date-fns";
import PaymentCard from "../payments-page/PaymentCard";
import DetailPair from "../UI/DetailPair";

// Internal Imports

/** ========================================================================
 * * CustomerPayments Component
 * This component renders a page that shows customer's payments.
 * ========================================================================== */

export default function CustomerPayments() {
  // Load Context
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState();
  const [year, setYear] = useState(new Date().getFullYear());
  const [filter, setFilter] = useState("");
  const [customer, setCustomer] = useState();

  // Get Customer & Session ID from URL
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  /* ====================== GET CUSTOMER PAYMENTS  ====================== */
  const fetchCustomer = async () => {
    try {
      setLoading(true);
      const data = await requestAdminAPI("fetch-customer", { customerId: id });
      setCustomer(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setWarning(err.message);
      console.error(err);
    }
  };

  /* ====================== GET GRAPH DATA  ====================== */
  const getGraphData = () => {
    const data = [];
    for (let i = 0; i < 12; i++) {
      data.push({
        month: format(new Date(year, i, 1), "MMM"),
        paid: 0,
        unpaid: 0,
        paidCount: 0,
        unpaidCount: 0,
      });
    }
    customer?.payments?.forEach((payment) => {
      const date = timestampToDate(payment.date_created);
      if (date.getFullYear() !== year) return;
      if (payment.status === "succeeded") {
        data[date.getMonth()].paid = Number(
          Number(data[date.getMonth()].paid) + Number(payment.amount)
        ).toFixed(2);
        data[date.getMonth()].paidCount++;
      } else {
        data[date.getMonth()].unpaid = Number(
          Number(data[date.getMonth()].unpaid) + Number(payment.amount)
        ).toFixed(2);
        data[date.getMonth()].unpaidCount++;
      }
    });
    return data;
  };

  /* ====================== GET TOTAL ====================== */
  const getTotals = () => {
    let paid = 0;
    let unpaid = 0;
    customer?.payments?.forEach((payment) => {
      const date = timestampToDate(payment.date_created);
      if (date.getFullYear() !== year) return;
      if (payment.status === "succeeded") {
        paid = Number(Number(paid) + Number(payment.amount)).toFixed(2);
      } else {
        unpaid = Number(Number(unpaid) + Number(payment.amount)).toFixed(2);
      }
    });
    return (
      <Fragment>
        <DetailPair label="Total Paid" value={`$${paid}`} />
        <DetailPair label="Total Unpaid" value={`$${unpaid}`} />
      </Fragment>
    );
  };

  useEffect(() => {
    if (id) fetchCustomer();
  }, []);

  if (!id)
    return (
      <div className="container">
        <p className="text-stone-400">Customer ID Not Provided!</p>
      </div>
    );

  if (!loading && !customer)
    return (
      <div className="container">
        <p className="text-stone-400">Customer Not Found!</p>
      </div>
    );

  return (
    <div className="container">
      <Heading title="Customer Payments">
        <select
          className="text-sm border-b rounded-none cursor-pointer input "
          value={year}
          onChange={(e) => setYear(e.target.value)}
        >
          {Array.from(
            { length: new Date().getFullYear() - 2022 + 1 },
            (_, i) => {
              return <option value={2022 + i}>{2022 + i}</option>;
            }
          )}
        </select>
      </Heading>
      {loading ? (
        <LoadingSpinnerWrapper />
      ) : (
        <div className="grid gap-4">
          <div className="md:grid-cols-5 info">
            <DetailPair
              label="Customer Name"
              value={`${customer.firstName} ${customer.lastName}`}
            />
            <DetailPair label="Phone Number" value={`${customer.phone}`} />
            {getTotals()}

            {customer.booking && (
              <DetailPair
                label="Current Booking"
                value={`${customer.booking?.make} ${customer.booking?.model} ${customer.booking?.year}`}
                link={`/bookings?id=${customer.booking?.id}`}
              />
            )}
          </div>
          <div className="w-full my-4 md:my-8 h-52 md:h-72">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={getGraphData()}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="month" />
                <YAxis tick={{ fontSize: 12 }} />
                <Tooltip
                  content={({ active, payload }) =>
                    active && payload && payload.length ? (
                      <div className="p-4 bg-white rounded-lg text-stone-900">
                        <p>{`Paid: $${payload[0]?.value} (${payload[0].payload?.paidCount})`}</p>
                        <p>{`Unpaid: $${payload[1]?.value} (${payload[0].payload?.unpaidCount})`}</p>
                      </div>
                    ) : null
                  }
                />
                <Bar dataKey="paid" stackId="a" fill="#ca8a04" />
                <Bar dataKey="unpaid" stackId="b" fill="#991b1b" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="flex justify-end">
            <select
              className="text-sm border-b rounded-none cursor-pointer input "
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="">All</option>
              <option value="succeeded">Succeeded</option>
              <option value="pending">Pending</option>
              <option value="refunded">Refunded</option>
              <option value="delinquent">Delinquent</option>
            </select>
          </div>
          <div className="list max-h-[420px]">
            {customer?.payments?.map((payment) => {
              if (payment.status.includes(filter))
                return <PaymentCard payment={payment} />;
            })}
          </div>
          {warning && <p className="warning">{warning}</p>}
        </div>
      )}
    </div>
  );
}
