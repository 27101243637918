import { useState, useContext, useEffect } from "react";
import { DataContext } from "../../context/data-context";
import DropDownSelector from "../UI/DropDownSelector";
import BarGraph from "../UI/BarGraph";
import { getVehicleStats } from "../../api/fetch";
import { AuthContext } from "../../context/auth-context";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import { arrayToCsv } from "../../util/arrayToCsv";
import { FiDownload } from "react-icons/fi";

export default function VehicleRevenue({ vehicles, forPartner }) {
  const { user } = useContext(AuthContext);

  // Create Ids Array for DropDownSelector
  const ids = vehicles.map((vehicle) => {
    return {
      value: vehicle.id,
      label: `${vehicle.model} ${vehicle.year} ${vehicle.color} ${vehicle.license}`,
      image: vehicle.images?.corner,
    };
  });

  // Initialize States
  const [selected, setSelected] = useState(ids.map((vehicle) => vehicle.value));
  const [selectedYear, setSelectedYear] = useState("2024");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // ============================================= FETCH REVENUE FOR VEHICLES
  const fetchVehicleRevenueHandler = async () => {
    try {
      if (!selected.length) {
        setData([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      const data = await getVehicleStats(user, {
        vehicles: selected,
        year: selectedYear,
        forPartner,
      });

      setData(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  // =================== DOWNLOAD CSV ===================
  const downloadCSV = () => {
    arrayToCsv(data, "vehicle-revenue");
  };

  useEffect(() => {
    fetchVehicleRevenueHandler();
  }, []);

  if (loading)
    return (
      <div className="h-[390px]">
        <LoadingSpinnerWrapper />
      </div>
    );

  return (
    <div>
      <div className="grid grid-cols-3 gap-8">
        <DropDownSelector
          label="Select Vehicles"
          options={ids}
          selected={selected}
          setSelected={setSelected}
        />
        <select
          className="text-sm border-b border-yellow-600 rounded-none cursor-pointer input"
          defaultValue={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
        >
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
        </select>
        <div className="flex items-center space-x-6">
          <div
            onClick={fetchVehicleRevenueHandler}
            className="w-full text-sm btn-tertiary"
          >
            Apply
          </div>
          <FiDownload onClick={downloadCSV} className="text-2xl icon" />
        </div>
      </div>
      <BarGraph data={data} expenses={forPartner || false} />
    </div>
  );
}
