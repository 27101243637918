// External Packages
import { useState, useContext, useEffect } from "react";
import { BsPlusLg } from "react-icons/bs";

// Internal Components
import { UtilityContext } from "../../context/util-context";
import { requestAdminAPI } from "../../api/posh-api";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import Heading from "../UI/Heading";

/** ========================================================================
 * * Employees Page
 * Renders a list of all employees.
 * ========================================================================== */

export default function Employees() {
  // Load Context
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);

  /* ======================= FETCH ALL EMPLOYEES ======================= */
  const fetchEmployeesHandler = async () => {
    try {
      setLoading(true);
      const response = await requestAdminAPI("fetch-all-employees", {});
      setEmployees(response);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployeesHandler();
  }, []);

  if (loading)
    return (
      <div className="container">
        <LoadingSpinnerWrapper />
      </div>
    );

  return (
    <div className="container grid">
      <Heading title="Employees" isBackArrow={false}>
        <BsPlusLg
          onClick={(e) => navigate("/employees/add", e)}
          className="text-2xl icon"
          title="Add Employee"
        />
      </Heading>

      <div className="list">
        <div className="grid items-start gap-4">
          <div className="hidden gap-6 mx-4 text-sm md:grid md:grid-cols-6 text-stone-400">
            <p className="md:col-span-2">Employee</p>
            <p className="md:col-span-2">Description</p>
            <p>Date</p>
            <p>Amount</p>
          </div>
          {employees &&
            employees.map((employee) => (
              <div
                onClick={() => navigate(`/employees/info?id=${employee.id}`)}
                className="text-sm pill"
              >
                <p>{employee.name}</p>
              </div>
            ))}
          {employees && employees.length === 0 && (
            <p className="text-center text-stone-400">No records found!</p>
          )}
        </div>
      </div>
    </div>
  );
}
