// External Imports
import { useState, useContext, useEffect } from "react";
import { BsArrowRightSquare, BsPlusLg } from "react-icons/bs";
import { BsArrowLeftSquare } from "react-icons/bs";
import { MdRefresh } from "react-icons/md";
import { BiDownload } from "react-icons/bi";
import { useLocation } from "react-router-dom";

// Internal Imports
import { DataContext } from "../../context/data-context";
import { UtilityContext } from "../../context/util-context";
import {
  timestampToDate,
} from "../../util/timestamp-to-date";
import Radio from "../UI/Radio";
import BookingCard from "./BookingCard";
import LoadingSpinnerWrapper from "../UI/LoadingSpinnerWrapper";
import SearchBar from "../UI/SearchBar";
import Heading from "../UI/Heading";
import { downloadBookingsCSV } from "../../util/downloadBookingsCsv";

/** ========================================================================
 * * Bookings Page
 * Renders a page, which displays all bookings.
 * ========================================================================== */

export default function BookingsPage() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const searchParam = params.get("search") ?? "";
  const tabParam = params.get("tab") ?? "Active";

  // Load Context
  const { bookings, loadingData, fetchBookings, vehicles, customers } =
    useContext(DataContext);
  const { navigate } = useContext(UtilityContext);

  // Initialize States
  const [selected, setSelected] = useState(tabParam);
  const [searchValue, setSearchValue] = useState(searchParam);
  const [filteredBookings, setFilteredBookings] = useState([]);

  /* ======================= APPLY FILTER BOOKINGS ======================= */
  const applyFilters = () => {
    let records = [...bookings];
    // Apply Tab selection filter
    if (selected) {
      records = filterBookings(records);
    }
    //Apply search filter
    if (searchValue) {
      records = searchBookings(records);
    }
    setFilteredBookings([...(records ?? [])]);
  };

  /* ======================= FILTER BOOKINGS ======================= */
  const filterBookings = (data) => {
    let records = [...data];
    switch (selected) {
      case "Active":
        records = records.filter((booking) => booking.status === "active");
        // Active bookings are sorted by end date first
        records.sort((a, b) => {
          return timestampToDate(a.end_date) - timestampToDate(b.end_date);
        });
        break;
      case "Upcoming":
        records = records.filter(
          (booking) =>
            booking.status === "confirmed" || booking.status === "pending"
        );
        // Upcoming bookings are sorted by start date first
        records.sort((a, b) => {
          return timestampToDate(a.start_date) - timestampToDate(b.start_date);
        });
        break;
      case "Completed":
        records = records.filter(
          (booking) =>
            booking.status === "completed" || booking.status === "cancelled"
        );
        // Completed bookings are sorted by end date first
        records.sort((a, b) => {
          return timestampToDate(b.end_date) - timestampToDate(a.end_date);
        });
        break;

      default:
        return records;
    }
    return records;
  };

  /* ======================= SEARCH BOOKINGS ======================= */
  const searchBookings = (records) => {
    return records.filter((booking) => {
      const string = `${booking.customer.firstName} ${booking.customer.lastName}
    ${booking.vehicle.make} ${booking.vehicle.license} ${booking.vehicle.model} ${booking.vehicle.year}
    ${booking.location}`.toLowerCase();
      return string.includes(searchValue.toLowerCase());
    });
  };

  /* ======================= DOWNLOAD CSV ======================= */
  const downloadCSV = async () => {
    if (!filteredBookings) return;
    downloadBookingsCSV(filteredBookings, vehicles);
  };

  // Fetch Bookings on load
  useEffect(() => {
    applyFilters();
  }, [selected, searchValue]);

  const handleTabClick = (option) => {
    setSelected(option);
    navigate(
      `/bookings?search=${searchParam}&tab=${option}`,
      {},
      { replace: true }
    );
  };
  // Clear Search Filter
  const clearSearch = () => {
    setSearchValue("");
    navigate(`/bookings?search=&tab=${selected}`, {}, { replace: true });
  };
  // Search handler
  const searchHandler = (value) => {
    navigate(
      `/bookings?search=${value}&tab=${selected}`,
      {},
      { replace: true }
    );
    setSearchValue(value);
  };
  return (
    <div className="container">
      <Heading title="Bookings" isBackArrow={false}>
        <BiDownload
          onClick={downloadCSV}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
          title="Download CSV"
        />
        <BsPlusLg
          onClick={(e) => navigate("/bookings/add", e)}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
        />
        <MdRefresh
          onClick={fetchBookings}
          className="text-2xl text-white cursor-pointer hover:text-stone-400"
        />
      </Heading>

      <SearchBar
        searchHandler={searchHandler}
        clearSearch={clearSearch}
        searchValue={searchValue}
      />

      <div className="grid grid-cols-3 gap-4 h-fit">
        <Radio
          selected={selected}
          options={["Active", "Upcoming", "Completed"]}
          onClick={handleTabClick}
        />
      </div>

      <div className="list">
        {loadingData ? (
          <LoadingSpinnerWrapper />
        ) : (
          <div className="list">
            {filteredBookings.map((booking) => (
              <BookingCard
                booking={booking}
                key={booking.id}
                customer={
                  customers.filter((c) => c.id === booking.customer.id)[0]
                }
              />
            ))}
            {filteredBookings.length === 0 && (
              <p className="my-4 text-stone-400">No bookings found</p>
            )}
          </div>
        )}
      </div>

      <div className="flex items-center justify-end space-x-4 text-sm text-stone-200">
        <p className="text-stone-400">
          Showing {filteredBookings.length} of {filteredBookings.length}{" "}
        </p>
        <BsArrowLeftSquare className="inline-block text-xl" />
        <BsArrowRightSquare className="inline-block text-xl" />
      </div>
    </div>
  );
}
