// External Packages
import { useContext } from "react";
import {
  MdLabel,
  MdLabelOff,
  MdLocationOff,
  MdLocationOn,
  MdPerson,
} from "react-icons/md";

// Internal Packages
import { UtilityContext } from "../../context/util-context";

export default function VehicleCard({ vehicle, onClick = false }) {
  const { navigate } = useContext(UtilityContext);

  return (
    <div
      onClick={
        onClick
          ? onClick
          : (e) => navigate(`/vehicles/info?id=${vehicle.id}`, e)
      }
      className={`${
        vehicle.status.available
          ? vehicle.bookings?.length === 0
            ? "border-green-600"
            : ""
          : "opacity-50"
      } grid items-center gap-4 text-sm md:grid-cols-8 pill grid-cols-2 text-stone-200`}
    >
      <img alt="Vehicle" src={vehicle.images.corner} className="w-24"></img>
      <div className="grid">
        <p className="font-semibold text-stone-200">
          {vehicle.model} {vehicle.year}
        </p>
        <p className="font-semibold text-stone-400">
          {vehicle.license} {vehicle.license_state}
        </p>
      </div>
      <p className="hidden md:block md:col-span-2">{vehicle.vin}</p>
      <div className="hidden text-center md:block">
        <p className="text-stone-200">${vehicle.rates.daily}/day</p>
        <p className="text-stone-200">${vehicle.rates.monthly}/month</p>
      </div>

      {vehicle.bookings?.length > 0 ? (
        <div className="hidden text-center md:block md:col-span-2">
          <p>
            {vehicle.bookings[0].firstName} {vehicle.bookings[0].lastName}
          </p>
        </div>
      ) : (
        <p className="hidden text-center md:block md:col-span-2">not booked</p>
      )}

      <div className="flex space-x-4">
        {vehicle.samsaraId ? (
          <MdLocationOn className="text-green-600 icon" />
        ) : (
          <MdLocationOff className="text-red-500 icon" />
        )}
        {vehicle.partnerId ? (
          <MdPerson className="text-green-600 icon" />
        ) : (
          <MdPerson className="text-red-500 icon" />
        )}
        {vehicle.fleetitId ? (
          <MdLabel className="text-green-600 icon" />
        ) : (
          <MdLabelOff className="text-red-500 icon" />
        )}
      </div>
    </div>
  );
}
