// External Imports
import { Fragment, useEffect, useRef, useState } from "react";

// Internal Imports

/** ========================================================================
 * * DropDownSearch Component
 * Renders a dropdown search component, which allows selecting an option from a list.
 * ========================================================================== */

export default function DropDownSearch({
  title,
  options,
  nameFormatter,
  selectHandler,
  includeAll,
  selectedValue,
}) {
  // Initialize States
  const [filtered, setFiltered] = useState(options || []);
  const [show, setShow] = useState(false);

  // Declare Refs
  const inputRef = useRef(null);
  const valueRef = useRef(null);

  /* ======================= OPTION CLICK HANDLER ======================= */
  const optionClickHandler = (input, value) => {
    inputRef.current.value = input;
    valueRef.current.value = value;
    setShow(false);
    selectHandler(value);
  };

  /* ======================= SEARCH HANDLER ======================= */
  const searchHandler = (query) => {
    const newFilter = options?.filter((vehicle) => {
      let match = true;
      const string =
        `${vehicle.make} ${vehicle.model} ${vehicle.year} ${vehicle.vin} ${vehicle.license} 
        ${vehicle.location} ${vehicle.plan.name} ${vehicle.license_state} ${vehicle.status.available}`.toLowerCase();
      query.split(" ").forEach((word) => {
        if (!string.includes(word.toLowerCase())) match = false;
      });
      return match;
    });
    setFiltered(newFilter);
  };

  // useEffect
  useEffect(() => {
    if (selectedValue) {
      const selectedOption = options.find((item) => item.id === selectedValue);
      if (selectedOption) {
        inputRef.current.value = nameFormatter(selectedOption);
        valueRef.current.value = selectedValue;
      }
    }
  }, [selectedValue, options, nameFormatter]);

  useEffect(() => {
    setFiltered(options);
  }, [options]);

  if (!title || !options || !nameFormatter || !selectHandler) return null;
  return (
    <Fragment>
      {show && (
        <div
          onClick={() => setShow(false)}
          className="absolute top-0 left-0 w-full h-full -z-1 no-scrollbar"
        ></div>
      )}
      <div className="relative grid gap-2 md:col-span-2">
        <label className="text-sm text-stone-400">{title}:</label>
        <input
          onClick={() => setShow(true)}
          type="text"
          className="relative form-input"
          onChange={(e) => searchHandler(e.target.value.toLowerCase())}
          ref={inputRef}
        />
        <input
          className="hidden"
          name={title.toLowerCase()}
          id={title.toLowerCase()}
          ref={valueRef}
        />
        {show && (
          <div className="absolute top-20 py-2 px-4 w-full overflow-scroll rounded-lg bg-stone-700 no-scrollbar max-h-[300px] z-10">
            {includeAll && (
              <div
                className="px-2 py-1 text-sm font-semibold rounded-md cursor-pointer hover:bg-stone-600"
                onClick={() => optionClickHandler("All", "All")}
              >
                All
              </div>
            )}
            {filtered.map((item) => (
              <div
                key={item.id}
                className="px-2 py-1 text-sm font-semibold rounded-md cursor-pointer text-stone-200 hover:bg-stone-600"
                onClick={() => optionClickHandler(nameFormatter(item), item.id)}
              >
                {nameFormatter(item)}
              </div>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
}
